import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { Grid, Typography, LinearProgress, Tooltip } from "@material-ui/core"

import { makeStyles, withStyles } from "@material-ui/core/styles"

import SwiperCore, { Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.min.css"
import "swiper/swiper-bundle.min.css"
import "swiper/components/pagination/pagination.min.css"

import { MdAttachFile, MdBadge, MdChevronRight, MdErrorOutline } from "react-icons/md"
import { MdOutlineRefresh } from "react-icons/md"

import useAuth from "../../hooks/useAuth"
import useTabs from "../../hooks/useTabs"
import useAlert from "../../hooks/useAlert"
import useModal from "../../hooks/useModal"
import Header from "../../components/Header"
import { swalAlert } from "../../helpers/swalAlert"
import MainButton from "../../components/MainButton"
import MobileContainer from "../../components/MobileContainer"
import { GetVacancyDocuments } from "../../services/api"
import { getMostRecentDocument, statusText } from "../../helpers/documentHelpers"
import { showFormatedCpf, calculateProgressBar, formatDateBugFix } from "../../helpers/format"
import GoBackHeader from "../../components/goBackHeader"
import { FaCheck, FaQuestion, FaUpload } from "react-icons/fa"
import DeleteClearButton from "../../components/DeleteClearButton"
import { ErrorOutline } from "@material-ui/icons"
import { FiUpload } from "react-icons/fi"
import useIsLinkRoute from "../../hooks/useIsLinkRoute"
import useDocumentLink from "../../hooks/useDocumentLink"
import ConfirmButton from "../../components/ConfirmButton"
import { useFetchVacancyDetail } from "../DocumentLink/FichaCadastral/useFetchVacancyDetail"

const WhiteTooltip = withStyles(theme => ({
  tooltip: {
    border: "1px solid",
    background: "#FFF",
    borderColor: theme.palette.secondary.main,
  },
  tooltipPlacementBottom: {
    marginTop: 0,
  },
}))(Tooltip)

export default function DocumentsList() {
  const history = useHistory()

  SwiperCore.use([Pagination])

  const { setTab } = useTabs()

  const { setImages } = useAuth()

  const { alert, setAlert } = useAlert()

  const { modal, setModal } = useModal()

  const [vacancy, setVacancy] = useState({})

  const [documents, setDocuments] = useState([])

  const [sentDocuments, setSentDocuments] = useState(0)

  const [totalDocuments, setTotalDocuments] = useState(0)

  const [slideAtual, setSlideAtual] = useState(0)

  const [vacancyDetails, setVacancyDetails] = useState({})

  const { user } = useDocumentLink();

  const nrChamado = vacancyDetails?.nrChamado || user.nrChamado || sessionStorage.getItem("nrChamadoLink");

  const [isRefreshing, setIsRefreshing] = useState(false)

  const [isSending, setIsSending] = useState(false)

  const isLinkRoute = useIsLinkRoute();

  const [attachedDocuments, setAttachedDocuments] = useState(0)

  const [loading, setLoading] = useState(false)

  useFetchVacancyDetail(nrChamado, setVacancyDetails, vacancyDetails, setLoading, loading, isLinkRoute);

  const spinKeyframes = {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  };

  const spinAnimation = {
    animation: "$spin 2s infinite linear",
  };

  const useStyles = makeStyles(theme => ({
    paperContainer: {
      filter: modal?.removeAllDocument ? "blur(3px)" : null,
      // height: "-webkit-fill-available",
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 447,
      marginBottom: 67,
    },
    cardContainer: {
      padding: 12,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: 16,
      paddingBottom: 12,
      marginBottom: 16,
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)"
    },
    cardJob: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: 16,
      borderRadius: 8,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 4px -2px rgba(77, 77, 77, 0.06), 0px 4px 8px -2px rgba(77, 77, 77, 0.10)",
      '&:hover': {
        cursor: 'pointer'
      },
      marginBottom: 8,
    },
    description: {
      fontSize: 14,
      lineHeight: "20px",
      color: '#4d4d4d'
    },
    messageDocuments: {
      fontSize: 12,
      lineHeight: "18px",
      display: 'flex',
      marginBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
      gap: 4,
      alignSelf: 'stretch',
      borderRadius: 8,
      width: '100%',
    },
    titleContent: {
      width: "100%",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    job: {
      fontWeight: 700,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: "#1d3054",
    },
    vacancySubtitles: {
      color: "#1d3054",
      fontWeight: 700,
    },
    takeVacancy: {
      color: "#1d3054",
      fontWeight: 700,
    },
    documentsOnboarding: {
      color: "#000",
      marginLeft: 24,
      marginBottom: 4,
      fontWeight: 700,
    },
    detailsGrid: {
      marginBottom: 8,
    },
    cv: {
      color: "#1d3054",
    },
    date: {
      color: "#1d3054",
    },
    docsStatus: {
      color: "#000",
      fontWeight: 700,
      marginLeft: "auto",
    },
    documentSent: {
      padding: 4,
      marginRight: 3,
      border: "4px solid",
      width: theme.spacing(2),
      height: theme.spacing(2),
      background: "transparent",
      borderColor: theme.palette.primary.main,
      boxShadow: "0 0 0 4px rgba(173,216,230,0.8)",
    },
    documentNotSent: {
      padding: 4,
      marginRight: 3,
      border: "4px solid",
      width: theme.spacing(2),
      height: theme.spacing(2),
      background: "transparent",
      borderColor: theme.palette.primary.main,
    },
    documentTitle: {
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    documentSubTitle: {
      color: theme.palette.primary.main,
    },
    sendDocument: {
      color: "#FFF",
      fontWeight: 700,
    },
    documentsGrid: {
      marginTop: 16,
      "&:last-child": {
        marginBottom: 24,
      },
    },

    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#e8e8e8',
      height: 40,
      width: 40,
      borderRadius: 4
    },

    textContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginLeft: 8,
      marginRight: 8
    },

    iconCard: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#15803D",
      background: "#DCFCE7",
      justifyContent: "center",
    },
    iconCardAttached: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#854D0E",
      background: "#FEF9C3",
      justifyContent: "center",
    },
    iconCardError: {
      padding: 8,
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      color: "#B91C1C",
      background: "#FEE2E2",
      justifyContent: "center",
    },
    iconCardNotSent: {
      display: "flex",
      borderRadius: 8,
      alignItems: "center",
      background: "#e8e8e8",
      justifyContent: "center",
    },
    infosAttached: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 4,
      width: '100%',
    },

    title: {
      color: '#1d3054',
      lineHeight: "20px",
      fontSize: 14,
      fontWeight: 700,
    },
    text: {
      color: '#4d4d4d',
      lineHeight: "18px",
      fontSize: 12,
      fontWeight: 400,
    },
    chevronContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 4
    },
    spin: spinAnimation,
    "@keyframes spin": spinKeyframes,
    spacer: { minHeight: 24 }

  }))

  const classes = useStyles()

  const getDocuments = async () => {
    console.log("getDocuments no documentsList", nrChamado)
    setIsRefreshing(true);
    setAlert({ ...alert, waitingResponse: true });
    let response;
    if (isLinkRoute) {
      if (!nrChamado) {
        swalAlert("error", "Vaga não encontrada, entre novamente pelo Link enviado para você", "Ok");
        history.push("/link/carregar/")
        return;
      }
      response = await GetVacancyDocuments(nrChamado);
    } else {
      let vacancyNumber = JSON.parse(localStorage.getItem("selectedVacancy")).nrChamado;
      response = await GetVacancyDocuments(vacancyNumber);
    }

    const targetRoute = isLinkRoute ? '/link/documentos' : '/auth/vagas-em-andamento';

    if (response?.status >= 200 && response?.status < 300) {
      setTotalDocuments(response.data.qtdeDocumentoTotal);
      setAttachedDocuments(response.data.qtdeDocumentoAnexado);
      setSentDocuments(response.data.qtdeDocumentoEnviado);
      setDocuments(response.data.listaDocumentosFormatada);
      setAlert({ ...alert, waitingResponse: false });
    } else {
      swalAlert("error", response, "TENTAR NOVAMENTE");
      setTimeout(() => history.push(targetRoute), 2000);
    }

    setAlert({ ...alert, waitingResponse: false });
    setIsRefreshing(false);
  }

  useEffect(() => {
    setVacancy(JSON.parse(localStorage.getItem("selectedVacancy")))

    localStorage.setItem("backPath", "/auth/vagas-em-andamento")

    setTab("1")

    setImages({ imagesArray: [] })
    getDocuments()

    if (!localStorage.getItem("page")) {
      localStorage.setItem("page", 0)
      setSlideAtual(0)
    } else {
      setSlideAtual(localStorage.getItem("page"))
    }
  }, [])

  const countDocumentsWithStatus = (documents, targetStatus) => {
    let count = 0;
    for (const document of documents) {
      const mostRecentDocument = getMostRecentDocument(document);
      if (mostRecentDocument && mostRecentDocument.status === targetStatus) {
        count++;
      }
    }
    return count;
  };

  const countDocumentsWithStatusDoUsuario = (documents, targetStatus) => {
    let count = 0;
    for (const document of documents) {
      if (document.statusDoUsuario === targetStatus && document.justificativa !== null && document.justificativa !== "") {
        count++;
      }
    }
    return count;
  };
  const handleClearVacancy = () => {
    localStorage.setItem("backPath", "/auth/documentos")
    setModal({ ...modal, removeAllDocument: true })
  }

  const documentType = (document) => {
    localStorage.setItem("documentToUpload", JSON.stringify(document));
    const pathToAnexar = isLinkRoute
      ? "/link/documentos/anexar"
      : "/auth/documentos/anexar";
    history.push(pathToAnexar);
  };

  const saveDocuments = async () => {
    try {
      const response = disableSentToCentralButton();
      if (response === false) {
        swalAlert("success", "Documentos salvos com sucesso!", "Ok");
        const targetRoute = isLinkRoute ? '/link/home' : '/auth/home';
        history.push(targetRoute);
      } else {
        console.error('Erro ao salvar os Documentos', response);
        swalAlert("error", "Ocorreu um erro ao salvar os documentos. Tente novamente.", "Ok");
      }
    } catch (error) {
      console.error('Erro ao salvar os Documentos', error);
      swalAlert("error", "Houve um erro ao salvar os documentos. Tente novamente.", "Ok");
    }
  };

  const disableSentToCentralButton = () => {
    const toCentral = sentDocuments + attachedDocuments
    if (sentDocuments >= totalDocuments || attachedDocuments >= totalDocuments || toCentral >= totalDocuments) {
      return false
    } else {
      return true
    }
  }

  const areAllMandatoryDocumentsApproved = (documents) => {
    for (const document of documents) {
      const mostRecentDocument = getMostRecentDocument(document);
      if (mostRecentDocument && (mostRecentDocument.status === "Reprovado" || mostRecentDocument.status === "Aguardando Textract" || (document.statusDoUsuario === "Documento Pendente" &&
        document.justificativa !== null &&
        document.justificativa !== ""))) {
        return false;
      }
      if (document.obrigatorio) {
        if (document.statusDoUsuario === "Documento Enviado") {
          continue;
        }
        if (
          mostRecentDocument &&
          (mostRecentDocument.status === "Aprovado" ||
            mostRecentDocument.status === "Aprovado Sem Textract" ||
            mostRecentDocument.status === "Aprovado Textract")
        ) {
          continue;
        } else {
          return false;
        }
      }
    }
    return true;
  };

  const hasDocumentAttached = (documents) => {
    for (const document of documents) {
      if (document.statusDoUsuario === "Documento Anexado") {
        return true;
      }
    }
    return false;
  };

  const isSendButtonDisabled = () => {
    return (
      !areAllMandatoryDocumentsApproved(documents) ||
      !hasDocumentAttached(documents) ||
      disableSentToCentralButton() ||
      isSending
    );
  };

  const getDocumentCardColor = (mostRecentDocument, document, classes) => {
    const status = mostRecentDocument?.status || '';
    const statusEnviado = document.statusDoUsuario || '';

    if (status === "Aprovado Textract" || status === "Aprovado" || status === "Aprovado Sem Textract" ||
      statusEnviado === "Documento Enviado" || (status === "Pendente" && document.justificativa === null)) {
      return classes.iconCard;
    } else if (status === "Aguardando Textract") {
      return classes.iconCardAttached;
    } else if (status === "Reprovado" || (statusEnviado === "Documento Pendente" && document.justificativa)) {
      return classes.iconCardError;
    } else {
      return classes.iconCardNotSent;
    }
  }

  const getStatusLabel = (document, mostRecentDocument, statusText) => {
    return document.statusDoUsuario === "Documento Enviado"
      ? "Enviado"
      : document.statusDoUsuario === "Documento Pendente"
        ? "Reprovado"
        : statusText[mostRecentDocument?.status];
  }

  const getStatusIcon = (mostRecentDocument, document) => {
    if (["Aprovado", "Aprovado Sem Textract", "Aprovado Textract"].includes(mostRecentDocument?.status) ||
      document?.statusDoUsuario === "Documento Enviado") {
      return <FaCheck style={{ fontSize: 16, marginRight: 8 }} />;
    } else if (["Aguardando Textract", "Pendente"].includes(mostRecentDocument?.status)) {
      return <FaUpload style={{ fontSize: 16, marginRight: 8 }} />;
    } else {
      return <MdErrorOutline style={{ fontSize: 16, marginRight: 8 }} />;
    }
  }

  const getDocumentText = (document) => {
    const documentMessages = {
      "Certificado de reservista": "Documento obrigatório (para homens entre 18 e 45 anos) *",
      "Comprovação eleitoral": "Obrigatório para Brasileiros *",
      "Registro geral (RG)": "Obrigatório preencher ao menos CNH ou RG *",
      "Carteira nacional de habilitação (CNH)": "Obrigatório preencher ao menos CNH ou RG *",
    };

    if (documentMessages[document.nome]) {
      return documentMessages[document.nome];
    }

    if (document.obrigatorio) {
      return (
        <>
          Documento obrigatório <span style={{ color: '#b91c1c', display: 'inline' }}>*</span>
        </>
      );
    }

    return "Documento opcional";
  };

  const renderDocuments = document => {

    const mostRecentDocument = getMostRecentDocument(document);
    const documentCardColor = getDocumentCardColor(mostRecentDocument, document, classes);
    const statusLabel = getStatusLabel(document, mostRecentDocument, statusText);
    const renderStatusIcon = getStatusIcon(mostRecentDocument, document);

    const handleTooltip = (document) => {
      let newDocs = [...documents]
      newDocs.forEach(temp => {
        if (temp.nome === document.nome) {
          if (temp.openTooltip) {
            temp.openTooltip = false
          } else {
            temp.openTooltip = true
          }
        }
      })
      setDocuments(newDocs)
    }

    return (
      <div
        className={classes.cardJob}
        key={document.nome}
        onClick={() => documentType(document)}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            documentType(document);
          }
        }}
      >
        {document.statusDoUsuario === "Documento Pendente" &&
          document.justificativa !== null &&
          document.justificativa !== "" ? (
          <WhiteTooltip
            arrow
            open={document?.openTooltip !== undefined && document?.openTooltip}
            title={
              <>
                <Grid container>
                  <Typography
                    variant='body2'
                    color='secondary'
                    style={{ fontWeight: 500, marginBottom: 4 }}
                  >
                    Documento reprovado
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant='caption' color='primary'>
                    {document?.justificativa}
                  </Typography>
                </Grid>
              </>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Grid
              item
              className={documentCardColor + " " + classes.icon}
              onMouseEnter={() => handleTooltip(document)}
              onMouseLeave={() => handleTooltip(document)}
              onClick={() => handleTooltip(document)}
            >
              <MdBadge style={{ color: '#1d3054', fontSize: 16 }} />
              <FaQuestion style={{ color: '#1d3054', fontSize: 12 }} />
            </Grid>
          </WhiteTooltip>
        ) : (
          <Grid
            item
            className={documentCardColor + " " + classes.icon}>
            <MdBadge style={{ color: '#1d3054', fontSize: 24 }} />
          </Grid>
        )}
        <div className={classes.textContainer}>
          <Typography variant="body2" className={classes.title}>
            {document.nome}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {getDocumentText(document)}
          </Typography>
          <div className={classes.infosAttached}>
            {(
              document.statusDoUsuario === "Documento Enviado" ||
              document.statusDoUsuario === "Documento Anexado" ||
              (document.statusDoUsuario === "Documento Pendente" &&
                document.justificativa !== null &&
                document.justificativa !== "")
            ) && (
                <Typography variant="body2" style={{
                  color: '#848484',
                  backgroundColor: '#F3F4F6',
                  borderRadius: 50,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 4,
                  paddingBottom: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 12,
                }}>
                  <MdAttachFile style={{
                    marginRight: 8,
                    fontSize: 16,
                  }} />
                  Anexo
                </Typography>
              )}
            {statusLabel && (
              document.statusDoUsuario === "Documento Enviado" ||
              document.statusDoUsuario === "Documento Anexado" ||
              (document.statusDoUsuario === "Documento Pendente" &&
                document.justificativa !== null &&
                document.justificativa !== "")
            ) && (
                <Typography variant="body2" style={{
                  fontSize: 12,
                  display: 'flex',
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 50,
                }} className={documentCardColor}>
                  {renderStatusIcon}
                  {statusLabel}
                </Typography>
              )}
          </div>
        </div>
        <div className={classes.chevronContainer}>
          <MdChevronRight style={{ color: '#1d3054' }} />
        </div>
      </div>
    )
  }

  const splitDocumentsArray = () => {
    const chunkSize = 4;
    const chunkCount = Math.ceil(documents.length / chunkSize);
    return Array.from({ length: chunkCount }, (_, i) =>
      documents.slice(i * chunkSize, i * chunkSize + chunkSize)?.map(item => renderDocuments(item))
    )
  }

  /* const backToPath = () => {
    history.push(localStorage.getItem("backPath"))
    localStorage.setItem('slide', "0")
  } */

  return (
    <MobileContainer style={{ padding: 0, backgroundColor: "#F3F4F6" }}>
      {!isLinkRoute && (
        <Grid style={{ backgroundColor: "#1C2F54" }}>
          <Header isFicha />
        </Grid>
      )}
      <GoBackHeader
        onGoBack={() => (isLinkRoute ? history.push("/link/home") : history.push("/auth/home"))}
      />

      <div className={classes.content}>
        <Grid container className={classes.paperContainer}>
          <Grid container className={classes.cardContainer}>
            <div className={classes.titleContent}>
              <div>
                <Typography variant="h6" className={classes.title}>
                  Documentos necessários
                </Typography>
                <Typography variant="body2" className={classes.description}>
                  Envie todos os documentos obrigatórios.
                </Typography>
              </div>
              <MdOutlineRefresh
                style={{
                  fontSize: 20,
                  color: '#1d3054',
                  cursor: 'pointer',
                }}
                className={isRefreshing ? classes.spin : ''}
                onClick={() => getDocuments()}
              />
            </div>

            <Grid container className={classes.card}>

              <Grid container style={{ marginBottom: 12 }} alignItems='center'>
                <Grid item xs={8} sm={8} md={8}>
                  <Tooltip arrow placement='top' enterTouchDelay={0} title={vacancy?.nome}>
                    <Typography variant='body1' className={classes.job}>
                      {vacancyDetails?.nome}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Typography variant='body2' className={classes.docsStatus}>
                  {sentDocuments + attachedDocuments}/{totalDocuments} docs
                </Typography>
              </Grid>

              <Grid container style={{ marginBottom: 8 }}>
                <LinearProgress
                  color='primary'
                  variant='determinate'
                  style={{ width: "100%", borderRadius: 8, height: 8 }}
                  value={calculateProgressBar((sentDocuments + attachedDocuments), totalDocuments)}
                />
              </Grid>
              <Grid container style={{ marginBottom: 8 }}>
                <Typography variant='caption' className={classes.cv}>
                  Número do chamado: <strong>{vacancyDetails?.nrChamado}</strong>
                </Typography>
              </Grid>


              {vacancyDetails?.dataAbertura && vacancyDetails.dataAbertura !== "01/01/0001" && (
                <Grid container style={{ marginBottom: 4 }}>
                  <Typography variant='caption' className={classes.date}>
                    Data abertura: <strong>{formatDateBugFix(vacancyDetails?.dataAbertura)}</strong>
                  </Typography>
                </Grid>
              )}

              <Grid container style={{ marginBottom: 4 }}>
                <Typography variant='caption' className={classes.cv}>
                  Empregado: <strong>{vacancyDetails?.empregado?.nome || ""}</strong>
                </Typography>
              </Grid>

              <Grid container style={{ marginBottom: 4 }}>
                <Typography variant='caption' className={classes.cv}>
                  CPF: <strong>{showFormatedCpf(vacancyDetails?.empregado?.cpf || "")}</strong>
                </Typography>
              </Grid>
            </Grid>

            {countDocumentsWithStatus(documents, "Reprovado") > 0 && (
              <div className={classes.messageDocuments} style={{ color: '#b91c1c', backgroundColor: '#FEE2E2' }}>
                <ErrorOutline style={{ fontSize: 16, marginRight: 8 }} />
                <p>Nº de documentos <strong>recusados</strong> pela central: {countDocumentsWithStatus(documents, "Reprovado") + countDocumentsWithStatusDoUsuario(documents, "Documento Pendente")}</p>
              </div>
            )}
            {countDocumentsWithStatus(documents, "Aguardando Textract") > 0 && (
              <div className={classes.messageDocuments} style={{ backgroundColor: '#FEF9C3', color: '#854D0E', }}>
                <FiUpload style={{ fontSize: 16, marginRight: 8 }} />
                <p> Nº de documentos <strong>aguardando análise</strong> pela central: <strong> {countDocumentsWithStatus(documents, "Aguardando Textract")}.</strong><br /> Se o processamento estiver demorando mais do que o normal, <strong>atualize a página.</strong></p>
              </div>
            )}

            {documents &&
              (documents?.length <= 4 ? (
                documents?.map(document => renderDocuments(document))
              ) : (
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  style={{ paddingBottom: 18 }}
                  onSlideChange={(e) => localStorage.setItem("page", e.activeIndex)}
                  initialSlide={slideAtual}
                  slidesPerView={1}
                  spaceBetween={40}
                >
                  {splitDocumentsArray()?.map(document => (
                    <SwiperSlide key={document.id}>
                      {document}
                    </SwiperSlide>
                  ))}
                </Swiper>
              ))}

            {!isRefreshing && (
              <ConfirmButton
                onClick={() => saveDocuments()}
                disabled={isSendButtonDisabled()}
              >
                {isSending ? "Salvando documentos..." : "SALVAR DOCUMENTOS"}
              </ConfirmButton>
            )}

            {!isLinkRoute && (
              <MainButton
                fullWidth
                size='small'
                style={{ marginBottom: 8, marginTop: 8, borderRadius: 8, height: 52, lineHeight: '52px', fontWeight: 700, fontSize: 16 }}
                buttonStyle='errorOutlined'
                onClick={handleClearVacancy}
                disabled={sentDocuments === totalDocuments || sentDocuments > 0}
              >
                DELETAR DOCUMENTOS
              </MainButton>
            )}
          </Grid>
        </Grid>
      </div>

      <DeleteClearButton
        open={modal.removeAllDocument}
        close={() => setModal({ ...modal, removeAllDocument: false })}
        typeButton={'clearVacancy'}
      />
    </MobileContainer>
  )
}
